import React from "react";

const useFormValidation = (initialState, validate) => {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState({});
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [submissionStatus, setSubmissionStatus] = React.useState({});

  React.useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        setSubmitting(false);
      } else {
        setSubmitting(false);
      }
    }
  }, [errors]);

  const hasErrors = errors => {
    const array = Object.keys(errors).filter(error => !!errors[error]);
    return array.length > 0;
  };

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({});
  };

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const handleSubmit = e => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);

    if (!hasErrors(validationErrors)) {
      setSubmitting(true);
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...values })
      })
        .then(() => setSubmissionStatus({ status: "SUCCESS" }))
        .catch(error => setSubmissionStatus({ status: "FAIL", error }));
    }
  };

  return {
    handleSubmit,
    handleChange,
    values,
    errors,
    isSubmitting,
    submissionStatus
  };
};

export default useFormValidation;
