const isEmail = email => {
  if (!email) {
    return "Required Email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return "Invalid email address";
  }
};

const isEmpty = (value, type) => {
  if (!value) {
    return "Required " + type;
  }
};

const validation = values => {
  let errors = {};
  errors.email = isEmail(values.email);
  errors.name = isEmpty(values.name, "Name");
  errors.subject = isEmpty(values.subject, "Subject");
  errors.body = isEmpty(values.body, "Body");

  return errors;
};

export default validation;
