import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./../../styles/modules/modal.module.scss";
import useFormValidation from "./useFormValidation";
import validation from "./validation";

const INITIAL_STATE = {
  name: "",
  email: "",
  subject: "",
  body: ""
};

const Modal = ({ title, handleClose, subject = "Subject" }) => {
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    submissionStatus,
    isSubmitting
  } = useFormValidation(INITIAL_STATE, validation);
  const [windowHeight, setWindowHeight] = useState(0);
  useEffect(() => {
    setWindowHeight(window.pageYOffset);
  }, []);

  return (
    <div className={styles.modalContainer}>
      <OutsideClickHandler onOutsideClick={() => handleClose()}>
        <div
          className={styles.modal}
          style={{ marginTop: windowHeight + "px" }}
        >
          {submissionStatus.status && submissionStatus.status === "SUCCESS" && (
            <>
              <h2>Your message was sent!</h2>
              <p>I will get back to you within the next working day.</p>
              <button
                type="button"
                className={`outline small ${styles.closeBtn}`}
                onClick={handleClose}
              >
                Close
              </button>
            </>
          )}
          {submissionStatus.status &&
            submissionStatus.status === "FAIL" &&
            "There was a server issue. Try again later or message me at hello@constantinchirila.com"}

          {!submissionStatus.status && (
            <>
              <h2>{title}</h2>
              <form action="" name="contact" onSubmit={handleSubmit}>
                <label htmlFor="name">Full name</label>
                <input
                  id="name"
                  onChange={handleChange}
                  name="name"
                  type="text"
                  value={values.name}
                  className={errors.name && "error-input"}
                />
                {errors.name && <div className="error-text">{errors.name}</div>}

                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  onChange={handleChange}
                  name="email"
                  type="email"
                  value={values.email}
                  className={errors.email && "error-input"}
                />
                {errors.email && (
                  <div className="error-text">{errors.email}</div>
                )}
                <label htmlFor="subject">{subject}</label>
                <input
                  id="subject"
                  onChange={handleChange}
                  name="subject"
                  type="text"
                  value={values.subject}
                  className={errors.subject && "error-input"}
                />
                {errors.subject && (
                  <div className="error-text">{errors.subject}</div>
                )}

                <label htmlFor="body">Message body</label>
                <textarea
                  id="body"
                  name="body"
                  rows="5"
                  onChange={handleChange}
                  value={values.body}
                  className={errors.body && "error-input"}
                ></textarea>
                {errors.body && <div className="error-text">{errors.body}</div>}

                <button
                  type="button"
                  className={`outline small ${styles.closeBtn}`}
                  onClick={handleClose}
                >
                  Close
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`small ${styles.sendBtn}`}
                >
                  Send message
                </button>
              </form>
            </>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default Modal;
