import React, { useState } from "react";
import Portal from "./common/portal";
import Modal from "./common/modal";
import footer from "./../styles/modules/footer.module.scss";

const Footer = () => {
  const [showForm, setShowForm] = useState(false);

  const openModal = () => {
    setShowForm(true);
  };

  const closeModal = () => {
    setShowForm(false);
  };
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className={footer.footer}>
      <div className={footer.container}>
        <div className={footer.first}>
          <h2>Let's work together</h2>
        </div>
        <div className={footer.second}>
          Interested in hiring me? We should schedule a chat!
        </div>
        <div className={footer.third}>
          <button type="button" onClick={() => openModal()} className="button">
            LET'S DO THIS
          </button>
        </div>
        <div className="clear"></div>
      </div>
      <div className={footer.social}>
        <a
          href="https://twitter.com/ConstantinC"
          title="Follow Constantin Chirila on Twitter"
        >
          <svg width="30" height="25" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 2.889c-1.104.49-2.289.822-3.534.97A6.19 6.19 0 0 0 29.171.452a12.393 12.393 0 0 1-3.91 1.497A6.131 6.131 0 0 0 20.769 0a6.16 6.16 0 0 0-6.154 6.164c0 .483.054.954.159 1.405C9.66 7.312 5.125 4.857 2.09 1.127a6.128 6.128 0 0 0-.832 3.099 6.166 6.166 0 0 0 2.737 5.131 6.146 6.146 0 0 1-2.788-.774v.077a6.17 6.17 0 0 0 4.936 6.046 6.222 6.222 0 0 1-1.621.216c-.398 0-.782-.04-1.159-.114a6.159 6.159 0 0 0 5.748 4.282 12.333 12.333 0 0 1-7.643 2.635c-.497 0-.986-.03-1.468-.084a17.372 17.372 0 0 0 9.433 2.774c11.321 0 17.51-9.395 17.51-17.543l-.021-.798A12.302 12.302 0 0 0 30 2.89z" />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/in/constantinchirila/"
          title="Constantin Chirila Linkedin profile"
        >
          <svg width="30" height="29" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.939 17.578v11.069h-6.417V18.32c0-2.595-.928-4.365-3.251-4.365-1.773 0-2.828 1.193-3.292 2.347-.17.413-.213.987-.213 1.565v10.78h-6.42s.087-17.491 0-19.303h6.42v2.736l-.043.062h.042v-.062c.853-1.313 2.376-3.19 5.784-3.19 4.224 0 7.39 2.76 7.39 8.688zM3.632.038C1.436.039 0 1.48 0 3.374 0 5.226 1.395 6.71 3.547 6.71h.043c2.238 0 3.63-1.484 3.63-3.337C7.178 1.48 5.828.04 3.632.04zM.382 28.648h6.416V9.344H.38v19.303z" />
          </svg>
        </a>
        <a
          href="https://github.com/ConstantinChirila"
          title="Constantin Chirila Github Profile"
        >
          <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.959 7.488a14.92 14.92 0 0 0-5.453-5.453C20.21.695 17.703.025 14.984.025c-2.718 0-5.226.67-7.521 2.01A14.918 14.918 0 0 0 2.01 7.488C.67 9.784 0 12.29 0 15.008c0 3.266.953 6.202 2.858 8.81 1.906 2.608 4.367 4.413 7.385 5.414.351.065.611.02.78-.136a.763.763 0 0 0 .254-.585l-.01-1.054c-.007-.663-.01-1.242-.01-1.736l-.448.077a5.725 5.725 0 0 1-1.083.069 8.25 8.25 0 0 1-1.356-.137 3.03 3.03 0 0 1-1.308-.585 2.476 2.476 0 0 1-.858-1.2l-.195-.448a4.875 4.875 0 0 0-.614-.995c-.28-.365-.563-.612-.85-.742l-.136-.097a1.432 1.432 0 0 1-.253-.235 1.07 1.07 0 0 1-.176-.273c-.039-.091-.007-.166.098-.225.104-.058.292-.087.566-.087l.39.058c.26.053.582.208.965.469.384.26.7.598.947 1.014.299.533.66.94 1.082 1.22.423.28.85.419 1.278.419.43 0 .8-.033 1.112-.097a3.88 3.88 0 0 0 .878-.293c.117-.872.436-1.542.956-2.01a13.36 13.36 0 0 1-2-.351 7.963 7.963 0 0 1-1.833-.762 5.253 5.253 0 0 1-1.571-1.307c-.416-.52-.758-1.203-1.024-2.048-.267-.846-.4-1.821-.4-2.927 0-1.574.514-2.914 1.541-4.02-.481-1.183-.436-2.51.137-3.98.377-.116.936-.028 1.678.264.741.293 1.284.544 1.629.751.345.208.621.384.83.527a13.854 13.854 0 0 1 3.745-.507c1.288 0 2.537.17 3.747.507l.741-.468a10.5 10.5 0 0 1 1.795-.859c.69-.26 1.216-.331 1.58-.214.586 1.47.638 2.796.156 3.98 1.028 1.106 1.542 2.446 1.542 4.02 0 1.105-.134 2.083-.4 2.936-.267.852-.611 1.534-1.034 2.048-.423.514-.95.947-1.58 1.298a7.978 7.978 0 0 1-1.834.76c-.592.157-1.259.274-2 .352.676.585 1.014 1.509 1.014 2.77v4.117c0 .234.081.43.244.585.163.156.42.202.77.136 3.019-1 5.48-2.806 7.386-5.414 1.905-2.607 2.858-5.544 2.858-8.809 0-2.717-.671-5.224-2.01-7.52z" />
          </svg>
        </a>
        <a
          href="mailto:hello@constantinchirila.com"
          title="Send Constantin Chirila an Email"
        >
          <svg width="30" height="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.311 23.563c.674 0 1.258-.222 1.755-.662l-8.497-8.496-.588.423c-.635.468-1.151.834-1.548 1.096-.396.262-.923.53-1.581.803-.659.273-1.272.41-1.841.41h-.033c-.57 0-1.183-.136-1.841-.41-.659-.273-1.186-.54-1.582-.803a39.413 39.413 0 0 1-1.548-1.096c-.177-.13-.374-.273-.586-.426l-8.498 8.5c.496.439 1.08.661 1.755.661H27.31zM1.69 9.054A8.872 8.872 0 0 1 0 7.598v12.927l7.489-7.488A578.933 578.933 0 0 0 1.69 9.054zM28.315 9.054a492.953 492.953 0 0 0-5.812 3.986l7.486 7.486V7.598a9.236 9.236 0 0 1-1.674 1.456z" />
            <path d="M27.311 0H2.678c-.86 0-1.52.29-1.983.87C.232 1.45 0 2.177 0 3.047 0 3.75.307 4.51.92 5.331c.614.82 1.267 1.464 1.959 1.932.379.268 1.522 1.063 3.43 2.385a1108.948 1108.948 0 0 1 4.385 3.054l.25.178.461.333c.363.262.664.474.904.636.24.162.53.343.87.544.34.2.661.351.962.452.302.1.58.15.837.15h.033c.257 0 .536-.05.837-.15.301-.1.622-.251.963-.452.34-.201.63-.382.87-.544.24-.162.541-.374.904-.636a112.242 112.242 0 0 1 .71-.51l1.694-1.178 6.139-4.262a7.96 7.96 0 0 0 2.041-2.058c.546-.803.82-1.645.82-2.527 0-.736-.265-1.366-.795-1.89C28.664.262 28.036 0 27.311 0z" />
          </svg>
        </a>
      </div>
      Copyright © {year === 2019 ? "2019" : "2019 - " + year} Constantin
      Chirila. All rights reserved.
      <form name="contact" netlify="true" style={{ display: "none" }}>
        <input name="name" type="text" />
        <input name="email" type="text" />
        <input name="subject" type="text" />
        <input name="body" type="text" />
      </form>
      {showForm ? (
        <Portal>
          <Modal title="Say hello!" handleClose={closeModal} />
        </Portal>
      ) : (
        ""
      )}
    </footer>
  );
};

export default Footer;
